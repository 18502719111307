<template>
  <div class="pledge-style">
    <div class="outbox center-center">
      <el-card class="box-card">
        <div class="box1 between-center">
          <div class="b1-t1"></div>
          <div class="b1-t2">True NFT</div>
          <img
            src="@/assets/img/img32.png"
            alt=""
            class="b1-t1"
            @click="golist"
          />
        </div>
        <van-divider>
          {{active==2?$t('text.t171'):$t('text.t172')}}
        </van-divider>
        <div class="box2">
          <van-tabs
            title-active-color="#323232"
            title-inactive-color="#909090"
            v-model="active"
            animated
            color="#323232"
            :line-width="80"
          >
            <van-tab :title="$t('text.t10')">
              <div class="box2-b1">
                <div class="box7 center-center" @click="goPledgeItem(1)">{{$t('text.t173')}}</div>
                <div class="box6 center-center" @click="goPledgeItem">{{$t('text.t174')}}</div>
              </div>
            </van-tab>
            <van-tab :title="$t('text.t176')">
              <div class="box2-b1">
                <div class="box7 center-center" @click="goAhead(`/pledgeitem/r?type=2&max=${config.max_number}`)">{{$t('text.t175')}}</div>
                <div class="box6 center-center" @click="goAhead(`/pledgeitem/r?max=${config.max_number}`)">{{$t('text.t177')}}</div>
              </div>
            </van-tab>
            <van-tab :title="$t('text.t229')">
              <div class="box2-b1">
                <div class="sybox between-center">
                  <div>{{$t('text.t178')}}:</div>
                  <div>{{parseFloat(info3.revenueTotal).toFixed(6)}} OMT</div>
                </div>
                <div class="box6 center-center" @click="goAhead(`/mpquery`)">{{$t('text.t340')}}</div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="box8">
          <div class="between-center box8-b1">
            <div>{{$t('text.t179')}}</div>
            <div>{{info1.pledged}}{{$t('text.t153')}}</div>
          </div>
          <div class="between-center box8-b1" v-if="active == 0">
            <div>{{$t('text.t180')}}</div>
            <div>{{info1.pledgable}}{{$t('text.t153')}}</div>
          </div>
          <div class="between-center box8-b1" v-if="active == 1">
            <div>{{$t('text.t181')}}</div>
            <div>{{info2.pledgable}}{{$t('text.t153')}}</div>
          </div>
          <template v-if="active == 2">
            <!-- <div class="between-center box8-b1">
              <div>{{$t('text.t335')}}</div>
              <div>{{info3.revenue}} OMT</div>
            </div> -->
            <div class="between-center box8-b1" v-if="user.info !== null">
              <div>{{$t('text.t332')}}</div>
              <div>{{info3.income}} OMT</div>
            </div>
            <div class="between-center box8-b1">
              <div>{{$t('text.t183')}}</div>
              <div>{{info3.earnings_time}}</div>
            </div>
          </template>
          <div class="between-center box8-b1" v-if="active == 0 || active == 1">
            <div class="start-center">
              <div>{{$t('text.t184')}}</div>
              <el-popover
                placement="top"
                :title="$t('text.t184')"
                width="190"
                trigger="click"
                :content="tipcontent"
              >
                <img
                  slot="reference"
                  src="@/assets/img/img41.png"
                  alt=""
                  class="b8-i1"
                />
              </el-popover>
            </div>
            <div>{{info1.pledge_day}}{{$t('text.t185')}}</div>
          </div>
          <div class="between-center box8-b1">
            <div>{{$t('text.t186')}}</div>
            <div>{{info1.whole_number}}%</div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
export default {
  components: {
  },
  data() {
    return {
      active: 0,
      info1:{},
      info2:{},
      info3:{},
      whitelist:["Pledgeitem","Pledgelist","Redemptionlist","Receivelist","Mpquery"]
    };
  },
  created(){
    this.getUser();
    this.getpledgeinfo()
    this.getredeeminfo()
    this.getgetinfo()
  },
  computed: {
    ...mapState(["config","user"]),
    tipcontent(){
      return this.$t('text.t187')
    }
  },
  mounted() {},
  beforeRouteLeave (to, from, next) {
    console.log(this.whitelist.includes(to.name));
    if(this.whitelist.includes(to.name)){
      from.meta.keepAlive = true
    } else{
      from.meta.keepAlive = false
    }
    next()
  },
  beforeRouteEnter (to, from, next) {
    console.log(to, from);
    if(to.name == 'Pledge' && from.name == 'Pledgeitem'){
      next(vm=>{
        vm.getpledgeinfo()
      })
    } else{
      next()
    }
  },
  methods: {
    ...mapActions(["getUser"]),
    golist(){
      if(this.active==0){
        this.goAhead('/pledgelist')
      } else if(this.active==1){
        this.goAhead('/redemptionlist')
      } else if(this.active==2){
        this.goAhead('/receivelist')
      }
    },
    getpledgeinfo(){
      this.$http.get('/index/pledge').then(res=>{
        this.info1 = res.data
      })
    },
    getredeeminfo(){
      this.$http.get('/index/redeem').then(res=>{
        this.info2 = res.data
      })
    },
    getgetinfo(){
      this.$http.get('/index/earnings').then(res=>{
        this.info3 = res.data
      })
    },
    goPledgeItem(type = 2){
      console.log(this.config.max_number,this.info1.pledged);
      if(this.config.max_number - this.info1.pledged <= 0){
        this.$toast(`${this.$t('text.t526')}${this.config.max_number}`)
        return
      }
      if(type == 1){
        this.goAhead(`/pledgeitem/p?type=1&max=${this.config.max_number - this.info1.pledged}`)
      } else{
        this.goAhead(`/pledgeitem/p?max=${this.config.max_number - this.info1.pledged}`)
      }
    }
  },
};
</script>

<style lang="less">
.pledge-style {
  .outbox {
    padding: 15px 0 30px;
    .box-card {
      width: 350px;
      border-radius: 20px;
      .van-divider {
        font-size: 9px;
        margin: 11px auto 26px;
      }
      .box1 {
        padding: 0 20px;
        .b1-t1 {
          width: 22px;
          height: auto;
        }
        .b1-t2 {
          font-size: 24px;
          font-weight: bold;
          color: #323232;
        }
      }
      .box2 {
        .van-tab {
          font-size: 18px;
          font-weight: bold;
        }
        .box2-b1 {
          padding: 28px 0 0;
        }
        .b2-i1 {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
        .b2-t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b2-t2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
      .box3 {
        width: 300px;
        box-sizing: border-box;
        height: 50px;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        padding: 0 20px;
        margin-bottom: 10px;
        .b3-input {
          width: 80%;
          border: none;
          background-color: transparent;
          font-size: 12px;
          font-weight: 600;
          color: #909090;
        }
        .b3-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .box4 {
        text-align: right;
        margin-bottom: 38px;
        font-size: 12px;
        font-weight: bold;
        color: #909090;
        padding-right: 20px;
      }
      .box5 {
        height: 40px;
      }
      .box6 {
        width: 290px;
        height: 46px;
        background: #2b2b2b;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        margin: 0 auto 15px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .sybox{
        width: 304px;
        height: 67px;
        background: #F4F4F4;
        border-radius: 10px;
        box-shadow: 4px -4px 0 #2B2B2B;
        margin: 0 auto 20px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        padding: 0 25px;
        box-sizing: border-box;
      }
      .box7 {
        width: 290px;
        height: 46px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #2b2b2b;
        margin: 0 auto 26px;
        font-size: 18px;
        font-weight: bold;
        color: #323232;
        border: 1px solid #323232;
        .b7-i1 {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .ad-mb24 {
        margin-bottom: 24px;
      }
      .ad-mb13 {
        margin-bottom: 13px;
      }
      .box8 {
        width: 305px;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        .box8-b1 {
          margin-bottom: 23px;
        }
        .box8-b1:last-of-type {
          margin-bottom: 0;
        }
        .b8-i1 {
          width: 10px;
          height: 10px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>